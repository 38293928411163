import React from "react";
import { navigate } from "gatsby";

import useTrialAuthClaimCheck, { PURCHASE_PATH, TRIAL_PATH } from "../../../components/profile/shared/AuthClaimCheckHook";
import PageContentContainer from "../../../components/profile/shared/PageContentContainer";
import { useTrialDataApi } from "../../../components/profile/trial/StatusContent";
import PurchaseContent from "../../../components/profile/trial/PurchaseContent";

import { pushSAEvent } from "../../../helpers";

export default ({ location }) => {
    const [loading, setLoading] = React.useState(true);
    const claimCheckOngoing = useTrialAuthClaimCheck(PURCHASE_PATH);
    const { getTenantStatus, tenantStatus } = useTrialDataApi();

    React.useEffect(() => {
        if (!claimCheckOngoing) {
            getTenantStatus();
        }
    }, [claimCheckOngoing]);

    React.useEffect(() => {
        if (!tenantStatus) {
            return;
        }

        if (tenantStatus.TrialStatus === 'Purchase Pending') {
            navigate(TRIAL_PATH);
            return;
        }

        setLoading(false);
    }, [tenantStatus]);

    const menuItems = [
        {
            path: TRIAL_PATH,
            text: 'Trial',
        },
        {
            path: PURCHASE_PATH,
            text: 'Purchase',
            open: true,
        },
    ];

    pushSAEvent('visit_profile_purchase');

    return (
        <PageContentContainer
            title="Your profile | Purchase"
            location={location}
            content={<PurchaseContent />}
            menuItems={menuItems}
            loading={loading}
        />
    );
};